@import "../../Responsive/Responsive.scss";

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    color: white;
    position: relative;
    overflow: hidden;

    .contact-background {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        .animation1 {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        .contact-back-gradient {
            position: absolute;
            background: linear-gradient(#000, #00000000, #000);
            z-index: 2;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    .contact-form {
        z-index: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        border: 2px solid #222;
        width: 100%;
        max-width: 600px;
        margin: 40px auto;
        position: relative;
        box-sizing: border-box;

        h1 {
            margin: 0 0 20px;
            font-size: 1.8em;
            color: #fff;
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            font-weight: 600;

            label {
                margin-bottom: 10px;
                color: #fff;

                input,
                textarea {
                    width: 100%;
                    padding: 10px;
                    border: 2px solid #222;
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 5px;
                    font-size: 1em;
                    color: #fff;
                    font-weight: 500;
                    font-family: Arial, sans-serif;

                    &:focus {
                        border-color: #007bff;
                        outline: none;
                    }
                }

                textarea {
                    min-height: 100px;
                    resize: vertical;
                }
            }

            .contact-button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px 24px;
                border-radius: 10px;
                color: white;
                background-color: #ffffff00;
                font-size: 1.1rem;
                font-weight: 600;
                border: 2px solid #222;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                margin-top: 20px;

                &:hover {
                    color: #28a745;
                    border-color: #28a745;
                }

                .button-status {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 1.1rem;
                    font-weight: 600;
                    color: #fff;
                    transition: opacity 0.5s ease;
                }
            }
        }

        .error {
            border-color: #ff0000;
        }

        .error-message {
            color: #ff0000;
            font-size: 0.9em;
            margin-top: 5px;
            display: block;
        }

        .social-media {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            gap: 40px;

            a {
                color: white;
                font-size: 24px;
                text-decoration: none;

                &:hover {
                    .fa-linkedin {
                        color: #3232ff;
                        text-shadow: 0 0 5px #3232ff;
                    }

                    .fa-twitter {
                        color: #00b7ff;
                        text-shadow: 0 0 5px #00b7ff;
                    }

                    .fa-instagram {
                        color: #ff00a2;
                        text-shadow: 0 0 5px #ff00a2;
                    }

                    .fa-github {
                        color: #ffffff;
                        text-shadow: 0 0 5px #ffffff;
                    }
                }
            }
        }

        .contact-para {
            margin-top: 20px;
            text-align: center;
            color: #fff;
            font-size: 1em;
            padding: 0 20px;

            p {
                margin: 0;
                line-height: 1.5;

                .email {
                    color: #00f;
                    font-weight: 500;
                }
            }
        }
    }

    @include tablet {
        .contact-back-gradient {
            background: rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(8px);
        }

        .contact-form {
            border: 2px solid #22222200;
        }
    }

    @include phone {
        .contact-background, .animation1 {
            display: none;
        }
    }
}