@import "../../Responsive/Responsive.scss";

.footer {
  background-color: black;
  border-top: 2px solid #222;
  color: white;
  user-select: none;
  font-size: 18px;
  z-index: 100;

  .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .image {
      display: flex;
      justify-content: center;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
      font-weight: 600;
    }

    .footer-column {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 10px 20px;

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 5px;
          padding: 0 px 100px;
          text-align: center;

          a {
            color: white;
            text-decoration: none;

            &:hover {
              text-shadow: 0 0 5px white;
            }
          }
        }
      }

      &.footer-links {
        display: flex;
        flex-direction: column;
        padding: 0px 30px;

        .dark {
          color: grey;
          text-decoration: none;

          &:hover {
          text-shadow: 0 0 0px rgb(0, 0, 0);
          }
          
        }

        .footer-links-section {
          flex: 1;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .footer-logo {
        width: 100px;
        margin-bottom: 10px;
      }

      &.footer-contact {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .social-icons {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          width: 160px;
        }

        a {
          color: white;
          font-size: 24px;
          text-decoration: none;

          &:hover {
            .fa-github{
              color: #ffffff;
              text-shadow: 0 0 5px #ffffff;
            }
            .fa-twitter{
              color: #00b7ff;
              text-shadow: 0 0 5px #00b7ff;
            }
            .fa-instagram{
              color: #ff00a2;
              text-shadow: 0 0 5px #ff00a2;
            }
            .fa-linkedin{
              color: #3232ff;
              text-shadow: 0 0 5px #3232ff;
            }
          }
        }

        .footer-contact-button{
          display: flex;
          align-items: center;
          justify-content: center;
  
          .contact-button {
            display: flex;
            align-items: center;
            border: 2px solid white;
            color: white;
            height: 40px;
            padding: 10px 20px;
            border-radius: 10px;
            text-decoration: none;
            font-size: 20px;
            font-weight: 600;
    
            &:hover {
              color: #bbb;
              border: 2px solid #bbb;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    padding: 10px 10px;
    border-top: 2px solid #222;
    color: #aaa;
  }

  @include phone {
    .footer-content {
      flex-direction: column;
    }

    .footer-column {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
  }

  @media (max-width: 780px) {
    .footer-contact-button .contact-button {
      font-size: 16px !important;
    }
  }

}
