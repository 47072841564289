@import "../../Responsive/Responsive.scss";

.about {
  position: relative; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: #fff;
  background-color: transparent;
  overflow: hidden; // Ensure no overflow issues

  .about-background-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; // Cover full viewport height
    z-index: -1; // Ensure it's behind content
    overflow: hidden; // Hide any overflow
  }

  .about-container {
    position: relative;
    max-width: 800px;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.8); // Darker background to make the animation more faded
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); // Slightly darker shadow for better contrast

    h1 {
      margin-bottom: 20px;
      font-size: 2em;
    }

    p {
      margin-bottom: 20px;
      line-height: 1.6;
    }

    .about-section {
      margin-bottom: 20px;
      padding: 15px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);

      h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
      }

      p {
        line-height: 1.5;
      }
    }
  }

  @include phone {
    .about-background-animation {
      display: none;
    }
  }
}
