@import "../../Responsive/Responsive.scss";

.background {
  .background-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; // Cover full viewport height
    z-index: -1; // Ensure it's behind content
    overflow: hidden; // Hide any overflow
  }
}