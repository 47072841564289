@import "../../Responsive/Responsive.scss";

.terms {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  color: #b9b9b9;
  line-height: 1.6;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
  }

  h2 {
    margin-top: 20px;
    color: #ffffff;
  }

  p {
    margin-bottom: 15px;
  }
}