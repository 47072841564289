@import "../../Responsive/Responsive.scss";
.privacy-policy {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    color: #c1c1c1;
    line-height: 1.6;
    
    h1 {
      text-align: center;
      margin-bottom: 20px;
      color: #ffffff;
    }
    
    h2 {
      margin-top: 20px;
      color: #ffffff;
    }
    
    p {
      margin-bottom: 15px;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
  