@import "../../Responsive/Responsive.scss";

.navbar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  z-index: 100;
  backdrop-filter: blur(20px);
  z-index: 200;


  .navbar-logo {
    display: flex;
    align-items: center;
  }

  .logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    padding: 5px;
    transition: all 200ms ease-in-out;

    .logo-image {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .company-name {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .menu-icon {
    display: none;
    font-size: 1.5em;
    padding: 5px 10px;
    cursor: pointer;
    color: white;
    z-index: 110;
  }

  .nav-links {
    list-style: none;
    display: flex;

    li {
      padding: 5px 10px;
      font-weight: 700;

      a {
        text-decoration: none;
        color: white;
        font-size: 1.2em;
        transition: color 0.3s;
        padding: 5px 5px;

        &:hover {
          text-shadow: 0 0 4px #fff;
        }
      }
    }
  }

  @include tablet {
    .menu-icon {
      display: block;
      color: white;
    }
  
    .nav-links {
      padding-top: 60px;
      display: none;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      background: rgba(0, 0, 0);
  
      li {
        width: 100%;
        margin: 20px 0;
  
        a {
          display: block;
          width: 100%;
          padding: 10px;
          font-size: 1.5em;

          &:hover {
            text-shadow: 0 0 4px #fff;
          }
        }
      }
    }
  
    .nav-links.active {
      display: flex;
      background: rgba(0, 0, 0);
    }
  }

  @include phone {
    padding: 0px 10px;
  }

}


