@import "../../Responsive/Responsive.scss";

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #ffffff;
  background-color: #111;

  .services-container {
    max-width: 1000px;
    width: 100%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
  
    h1 {
      margin-bottom: 20px;
      font-size: 2em;
      color:#007bff;
    }
  
    p {
      margin-bottom: 20px;
      line-height: 1.6;
    }
  
    .service-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
      justify-items: center;
  
      .service {
        padding: 20px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        text-align: center;
  
        h2 {
          font-size: 1.5em;
          margin-bottom: 10px;
        }
  
        p {
          line-height: 1.5;
          color:#bbb;
        }
      }
    }
  
    .contact-button {
      margin-top: 30px;
  
      .btn {
        display: inline-block;
        padding: 10px 20px;
        background: #007bff;
        color: #fff;
        font-weight: 600;
        border: none;
        border-radius: 8px;
        text-decoration: none;
        font-size: 1em;
        transition: background 0.2s;
  
        &:hover {
          border: 2px solid #007bff;
          color: #007bff;
          background: black;
        }
      }
    }
  }
  
}
