@import "../../Responsive/Responsive.scss";

.animation-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000; // Black background for better glow effect
  z-index: -1; // Ensure the animation is behind other content
}

