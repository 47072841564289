@import "../../Responsive/Responsive.scss";

.home {
  background-color: #000000;
  color: #fff;

  .banner {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: center;
    overflow: hidden;

    .banner-gradient-horizontal,
    .banner-gradient-vertical {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    .banner-gradient-horizontal {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    }

    .banner-gradient-vertical {
      background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.8));
    }

    .banner-animation-1 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .banner-text {
      position: relative;
      z-index: 3;
      max-width: 660px;
      padding: 10px;
      text-align: left;
      margin-left: 40px;
      margin-right: 40px;

      h1 {
        font-size: 4.6rem;
        margin-bottom: 1rem;
        font-weight: bold;
      }

      p {
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }

      .banner-buttons {
        margin-top: 2rem;
        display: flex;
        gap: 1rem;

        .primary-button,
        .secondary-button {
          padding: 0.8rem 1.5rem;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease, color 0.3s ease;
          text-decoration: none;
          font-weight: 600;
        }

        .primary-button {
          background-color: #007bff;
          color: #fff;
          border: none;

          &:hover {
            background-color: #005cbf;
          }
        }

        .secondary-button {
          background-color: transparent;
          color: #00bcd4;
          border: 2px solid #007bff;

          &:hover {
            background-color: #005cbf;
            color: #fff;
          }
        }
      }
    }


  }

  .home-sections {
    padding: 80px 100px;
    transition: padding 0.3s ease-in-out;

    .section {
      margin-bottom: 40px;
      text-align: center;
      padding: 20px;
      opacity: 0;
      transform: translateY(30px);
      transition: opacity 0.6s ease, transform 0.6s ease;      


      &.visible {
        opacity: 1;
        transform: translateY(0);
      }

      h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
        transition: color 0.3s ease-in-out; /* Smooth heading color change */
      }

      p {
        font-size: 1.2rem;
        line-height: 1.6;
        margin-left: auto;
        margin-right: auto;
        padding: 0 40px;
        transition: color 0.3s ease-in-out; /* Smooth paragraph color change */
      }

      .btn {
        display: inline-block;
        background-color: #007bff;
        color: #fff;
        padding: 0.8rem 1.5rem;
        border-radius: 5px;
        font-weight: 600;
        text-decoration: none;
        transition: background-color 0.3s ease, color 0.3s ease;
        margin-top: 1rem;

        &:hover {
          background-color: #005cbf;
        }
      }
    }
  }

  @include tablet {
    .banner {
      .banner-text {
        h1 {
          font-size: 4rem;
        }
      }

      .banner-buttons {
        flex-direction: column;
        
        .primary-button,
        .secondary-button {
          width: 100%;
        }
      }
    }
    
    .home-sections {
      padding: 80px 60px;
    }
  }

  @include phone {
    .banner {
      .banner-text {
        h1 {
          font-size: 3rem;
        }
        margin-bottom: 20px;
      }
      .banner-buttons {
        margin-top: 1rem;
      }
    }
  
    .home-sections {
      padding: 20px 10px; 
      padding-top: 0px;
      margin-top: 0px;

      section{
        margin: 10px;
        padding-top: 0px;
        margin-top: 0px;
      }
    }
  }
}
