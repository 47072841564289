@import "../../Responsive/Responsive.scss";

.career {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #fff;
  background-color: #111;

  .career-container {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;

    h1 {
      margin-bottom: 20px;
      font-size: 2em;
    }

    p {
      margin-bottom: 20px;
      line-height: 1.6;
    }

    .button-container {
      display: flex;
      justify-content: center;
      gap: 10px;

      .btn {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        font-size: 1em;
        transition: background 0.3s;
      }

      .home-btn {
        background: #007bff;
        
        &:hover {
          background: #0056b3;
        }
      }

      .contact-btn {
        background: #28a745;
        
        &:hover {
          background: #218838;
        }
      }
    }
  }
}

// .career {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
//   color: #fff;
//   background-color: #111;

//   .career-container {
//     max-width: 800px;
//     width: 100%;
//     padding: 20px;
//     background: rgba(0, 0, 0, 0.8);
//     border-radius: 10px;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

//     h1 {
//       margin-bottom: 20px;
//       font-size: 2em;
//       text-align: left;
//     }

//     p {
//       margin-bottom: 20px;
//       line-height: 1.6;
//       text-align: left;
//     }

//     .job-listings {
//       .job {
//         margin-bottom: 20px;
//         padding: 15px;
//         background: rgba(0, 0, 0, 0.7);
//         border-radius: 5px;
//         box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
//         text-align: left;

//         h2 {
//           font-size: 1.5em;
//           margin-bottom: 10px;
//         }

//         p {
//           line-height: 1.5;
//         }

//         .cta-btn {
//           margin-top: 10px;
//           padding: 10px 20px;
//           background: #007bff;
//           border: none;
//           border-radius: 5px;
//           color: #fff;
//           cursor: not-allowed;
//           text-align: center;
//           transition: background 0.3s;

//           &:hover {
//             background: #0056b3;
//           }
//         }
//       }
//     }
//   }
// }
