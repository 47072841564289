.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed; 
  top: 0;
  left: 50vw;
  height: 100vh; 
  background-color: #000;
  z-index: 1000;
}

#dots {
    display: block;
    position: absolute;
    top: 50%;
    left: calc(50% - 20px);
    height: 50px;
    width: 50px;
  }
  
  #dots span {
    position: absolute;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 100%;
    animation: dots 1s infinite ease-in-out;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  
  #dots span:nth-child(1) {
    left: -72px;
    animation-delay: 0.2s;
  }
  
  #dots span:nth-child(2) {
    left: -24px;
    animation-delay: 0.3s;
  }
  
  #dots span:nth-child(3) {
    left: 24px;
    animation-delay: 0.4s;
  }
  
  #dots span:nth-child(4) {
    left: 72px;
    animation-delay: 0.5s;
  }
  
  @keyframes dots {
    0% {
      transform: translateY(0px);
      background: rgb(0, 240, 255);
      box-shadow: 0 0 20px rgb(0, 240, 255);
    }
    25% {
      transform: translateY(10px);
      background: rgb(60, 0, 255);
      box-shadow: 0 0 20px rgb(60, 0, 255);
    }
    50% {
      transform: translateY(10px);
      background: rgb(130, 0, 240);
      box-shadow: 0 0 20px rgb(110, 0, 240);
    }
    100% {
      transform: translateY(0px);
      background: rgb(0, 140, 255);
      box-shadow: 0 0 20px rgb(0, 140, 255);
    }
  }
  