.article-detail {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2em;
    margin: 10px 0;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }
}
